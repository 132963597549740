<script>
import { ref } from 'vue'
import { useStore, mapGetters } from 'vuex'
import UserForm from '../components/pieces/form/base.vue'
export default{
    name: 'user-set',
    components: { UserForm },
    computed: {
        ...mapGetters('user', ['userFormSet'])
    },
    provide(){
        return {             
            formSet: this.userFormSet
        }       
    },
    setup(){
        let rightDrawer = ref(false)
        return {
            rightDrawer
        }
    }
}
</script>
<template>
    <div class="q-pa-md">
        <q-card square flat>
            <q-toolbar class="text-primary">
                <strong>用户相关设置</strong>
                <q-space /> 
                <!-- <q-btn dense flat  push round color="primary" @click="rightDrawer=!rightDrawer"  
                :icon="rightDrawer?'fas fa-angle-double-right':'fas fa-angle-double-left'" >
                    <q-tooltip>{{rightDrawer?'关闭辅助导航':'打开辅助导航'}}</q-tooltip>
                </q-btn>     -->
            </q-toolbar>
            <q-separator inset/>
            <q-card-section>
            
                <user-form />
            </q-card-section>
            
            
        </q-card>
        <!-- <q-drawer  side="right" v-model="rightDrawer" :width="200">
            <q-scroll-area class="fit">
            
            <q-list padding class="menu-list">
                <q-item-label header>辅助导航</q-item-label>
                <q-item clickable v-ripple>             
                <q-item-section>
                    
                </q-item-section>
                </q-item>
            </q-list>
            </q-scroll-area>
        </q-drawer> -->
    </div>
</template>